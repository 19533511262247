
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import ReactPlayer from 'react-player/vimeo'
import LoadContainer from '../../shared/LoadContainer';
import "./player.scss";

export default function ViewGalleryFlythrough() {
    const [isMovieActive, setIsMovieActive] = useState(true);

    const playMovie = () => {
        setIsMovieActive(true);
    }


    return (
        <LoadContainer>
            <img src='./img/gallery-fly-through/bkg--flythrough-1.jpg' width='1920' height='1080' alt='movies bkg' />
            <button className='movies--button__ird' onClick={() => playMovie()}></button>
            <CSSTransition className='movies--enlarged' in={isMovieActive} timeout={300} classNames='fade' unmountOnExit>
                <div>
                    <ReactPlayer className='movies--enlarged__movie' url={'https://player.vimeo.com/video/869001825'} playing={true} controls={true} width='100%' height='100%' />
                    <button className='movies--enlarged__close-btn'  onClick={() => setIsMovieActive(false)} > Close </button>
                   
                </div>
            </CSSTransition>
        </LoadContainer>
    );

}