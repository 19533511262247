
import React from 'react';
import ConnectViewResidencesRotation from './ConnectViewResidencesRotation';
import ConnectViewResidencesUIRotate from './ConnectViewResidencesUIRotate';
import ConnectViewResidencesLevelGroup from './ConnectViewResidencesLevelGroup';
import ConnectViewResidencesApartment from './ConnectViewResidencesApartment';
import LoadContainer from '../../shared/LoadContainer';
import './stylesResidences.scss';
import ConnectViewResidencesDayNight from './ConnectViewResidencesDayNight';

export default function ViewResidences () {
    
    const rotations = [];
    for (let i = 0; i <= 72; i++) { 
        rotations.push(
            <ConnectViewResidencesRotation key={ i } rotation={ i } />
        );
    }

    return (
        <LoadContainer>
            <div className='residences--rotations'> 
                { rotations }
            </div>
            <ConnectViewResidencesDayNight />
            <ConnectViewResidencesUIRotate />
            <ConnectViewResidencesLevelGroup />
            <ConnectViewResidencesApartment />
        </LoadContainer>
    );

}