
import React, { useRef, useEffect } from 'react';
import Slideshow from '../../shared/slideshow/Slideshow';
import LoadContainer from '../../shared/LoadContainer';
import '../../shared/slideshow/slideshow.scss';


export default function ViewHome () {
    
    const slideshowRef = useRef(null);
    useEffect(() => {
        new Slideshow( {
            container: slideshowRef.current,
            handlesResize: false
        } );
    });

    return (
        <LoadContainer>
            <div className='slideshow--container js-slideshow' ref={ slideshowRef } data-speed='4' data-is-full-screen='false' data-height-ratio='0.5625'>
                <div className='js-slidesContainer'>
                    <div className='slideshow--slide js-slide'>
                        <img className='shared--img-fill' src={process.env.PUBLIC_URL + '/img/home/bkg--home-1.jpg'} width='1922' height='1080' alt='' style={{ marginTop: 100 }} />
                    </div>
                    <div className='slideshow--slide js-slide'>
                        <img className='shared--img-fill' src={process.env.PUBLIC_URL + '/img/home/bkg--home-2.jpg'} width='1920' height='1080' alt='' style={{ marginTop: 100 }} />
                    </div>
                    <div className='slideshow--slide js-slide'>
                        <img className='shared--img-fill' src={process.env.PUBLIC_URL + '/img/home/bkg--home-3.jpg'} width='1920' height='1080' alt='' style={{ marginTop: 100 }} />
                    </div>
                </div>
                <div className='slideshow--pagination js-homePagination'>
                    <ul>
                        <li className='js-slideshowPagination'><a className='js-toThisSlide active' href='#show-image'>To Slide</a></li>
                        <li className='js-slideshowPagination'><a className='js-toThisSlide' href='#show-image'>To Slide</a></li>
                        <li className='js-slideshowPagination'><a className='js-toThisSlide' href='#show-image'>To Slide</a></li>
                    </ul>
                </div>
            </div>
        </LoadContainer>    
    );

}