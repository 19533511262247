export const menuItemData = [
  {
    menuItemLabel: 'Morning',
    type: 'standalone',
    dusk: process.env.PUBLIC_URL + '/vr-app/morning/index.htm',
    sunset: process.env.PUBLIC_URL + '/vr-app/morning/index.htm', //no toggle buttons?
  },
  {
    menuItemLabel: 'Evening',
    type: 'standalone',
    dusk: process.env.PUBLIC_URL + '/vr-app/evening/index.htm',
    sunset: process.env.PUBLIC_URL + '/vr-app/evening/index.htm', //no toggle buttons?
  },
];