import React from 'react';



const View3dAPP = ({ url }) => {
  //./vr-app-h3sa/index.htm
        return(
            <div>
                <iframe src={url} frameBorder={0} style={{ position: 'absolute', overflow: 'hidden', height: '1084px', width: '100%'}} height="1084px" width="100%" title="3DView" />
             </div>
        );
  }




export default View3dAPP;
//Photo by Sharon McCutcheon on Unsplash
//https://unsplash.com/photos/-vgLTTWPesg
