
import ViewHome from './home/ViewHome';
import ViewGalleryLocationLifestyle from './location-lifestyle/ViewGalleryLocationLifestyle';
import ViewLocationMap from './location-map/ViewLocationMap';
import ViewLocationAerial from './location-aerial/ViewLocationAerial';
import ViewResidences from './residences/ViewResidences';
import { locationlifestyle1, locationlifestyle2, amenities, building, team1, team2, team3, team4, team5, design1, design2, design3, design4, design5, design6, design7, design8, design9, interiorsImages1, interiorsImages2, interiorsImages3, interiorsImages4, interiorsImages5, interiorsImages6, interiorsImages7, interiorsImages8, interiorsImages9, interiorsFinishes1, interiorsFinishes2, interiorsFinishes3, interiorsFinishes4, interiorsFinishes5, interiorsFinishes6, interiorsFinishes7, interiorsDetail1, interiorsDetail2, interiorsDetail3, interiorsDetail4, interiorsDetail5, interiorsDetail6, interiorsDetail7, interiorsDetail8, interiorsDetail9, interiorsDetail10, interiorsDetail11, interiorsDetail12, interiorsDetail13, interiorsDetail14, interiorsDetail15, interiorsDetail16, interiorsDetail17, interiorsDetail18, interiorsDetail19, interiorsDetail20, interiorsDetail21, interiorsDetail22, interiorsDetail23, interiorsDetail24, interiorsDetail25, interiorsDetail26, interiorsDetail27, interiorsDetail28, interiorsDetail29, interiorsDetail30, interiorsDetail31, interiorsDetail32 } from './gallery/data/gallery';
import ViewVirtualTour from './tour/ViewVirtualTour';

import ViewInteriorsImages from './interiors-images/ViewInteriorsImages';
import ViewInteriorsFinishes from './interiors-finishes/ViewInteriorsFinishes';
import ViewInteriorsDetail from './interiors-detail/ViewInteriorsDetail';

import ViewGalleryTeam from './team/ViewGalleryTeam';

import ViewGalleryBuilding from './gallery-building/ViewGalleryBuilding';
import ViewGalleryAmenities from './gallery-amenities/ViewGalleryAmenities';
import ViewGalleryFlythrough from './gallery-fly-through/ViewGalleryFlythrough';
import ViewGalleryDesign from './design/ViewGalleryDesign';


export const dataRoutes = [
    {
        title: 'Location',
        routes: [
            {
                title: 'Lifestyle',
                path: '/location-lifestyle',
                component: ViewGalleryLocationLifestyle,
                data: [locationlifestyle1, locationlifestyle2],
            },
            {
                title: 'Map',
                path: '/location-map',
                component: ViewLocationMap
            },
            {
                title: 'Aerial',
                path: '/location-aerial',
                component: ViewLocationAerial
            }
        ]
    },
    {

        title: 'Team',
        path: '/team',
        component: ViewGalleryTeam,
        data: [team1, team2, team3, team4, team5],
    },
    {
        title: 'Design',
        path: '/design',
        component: ViewGalleryDesign,
        data: [design1, design2, design3, design4, design5, design6, design7, design8, design9],
    },
    {
        title: 'Interiors',
        routes: [
            {
                title: 'Images',
                path: '/interiors-images',
                component: ViewInteriorsImages,
                data: [interiorsImages1, interiorsImages2, interiorsImages3, interiorsImages4, interiorsImages5, interiorsImages6, interiorsImages7, interiorsImages8, interiorsImages9],
            },
            {
                title: 'Finishes',
                path: '/interiors-finishes',
                component: ViewInteriorsFinishes,
                data: [interiorsFinishes1, interiorsFinishes2, interiorsFinishes3, interiorsFinishes4, interiorsFinishes5, interiorsFinishes6, interiorsFinishes7],
            },
            {
                title: 'Detail',
                path: '/interiors-detail',
                component: ViewInteriorsDetail,
                data: [interiorsDetail1, interiorsDetail2, interiorsDetail3, interiorsDetail4, interiorsDetail5, interiorsDetail6, interiorsDetail7, interiorsDetail8, interiorsDetail9, interiorsDetail10, interiorsDetail11, interiorsDetail12, interiorsDetail13, interiorsDetail14, interiorsDetail15, interiorsDetail16, interiorsDetail17, interiorsDetail18, interiorsDetail19, interiorsDetail20, interiorsDetail21, interiorsDetail22, interiorsDetail23, interiorsDetail24, interiorsDetail25, interiorsDetail26, interiorsDetail27, interiorsDetail28, interiorsDetail29, interiorsDetail30, interiorsDetail31, interiorsDetail32],
            }
        ]
    },
    {
        title: 'Residences',
        path: '/residences',
        component: ViewResidences,

    },
    {
        title: 'Views',
        path: '/views',
        component: ViewVirtualTour
    },
    {

        title: 'Gallery',
        routes: [
            {
                title: 'Building',
                path: '/gallery-building',
                component: ViewGalleryBuilding,
                data: [building],
            },
            {
                title: 'Amenities',
                path: '/gallery-amenities',
                component: ViewGalleryAmenities,
                data: [amenities],
            },
            {
                title: 'Fly Through',
                path: '/gallery-flythrough',
                component: ViewGalleryFlythrough
            }
        ]
    },
    {
        title: 'Home',
        path: '/',
        component: ViewHome
    }

];