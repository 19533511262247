
export const data = [
    { 
        title: 'ENTERTAINMENT',
        options: [
            { title: 'James Street Precinct' },
            { title: '$2 billion Waterfront Brisbane Development' },
            { title: 'Sea Legs Brewing' },
            { title: 'Mobo Japanese' },
            { title: "Joey’s KP" },
            { title: 'Queen’s Wharf' },
            { title: "Story Bridge Hotel" },
            { title: "Moonshine Coffee" },
            { title: 'Howard Smith Wharves' },
            { title: 'Queen Street Mall' },
            { title: 'Riverstage' },

        ]
    },
    { 
        title: 'ACTIVE SPACES',
        options: [
            { title: 'New Farm Riverwalk' },
            { title: 'Botanical Gardens' },
            { title: 'New Farm Park' },
            { title: 'Captain Burke Park' },
            { title: 'CT White Park' },
            { title: 'Raymond Park' },

        ]
    },
    { 
        title: 'CONNECTIVITY',
        options: [
            { title: 'Story Bridge' },
            { title: 'Goodwill Bridge' },
            { title: 'Green Bridge' },

        ]
    }
];